const apiURLs = {
  promotion: {
    basePrefix: 'promotions',
    createPromotion: '',
    getPromotionsList: '',
    setPromotion: (promotionID) => `${promotionID}`,
    getPromotion: (promotionID) => `${promotionID}`,
    deletePromotion: (promotionID) => `${promotionID}`,
    enablePromotion: (promotionID) => `${promotionID}/enable`,
    disablePromotion: (promotionID) => `${promotionID}/disable`,
    setPromotionCategories: (promotionID) => `${promotionID}/categories`,
    searchPromotionCategories: (promotionID) => `${promotionID}/categories/search`,
    setPromotionProducts: (promotionID) => `${promotionID}/products`,
    getPromotionProductsList: (promotionID) => `${promotionID}/products`,
    searchPromotionProducts: (promotionID) => `${promotionID}/products/search`,
  },
  ecwid: {
    basePrefix: '',
    getProfile: 'profile',
  },
  feedback: {
    basePrefix: 'sendFeedback',
  },
};

export default apiURLs;
